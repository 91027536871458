import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
import Link from "@components/Link"

import css from "@css/components/blocks/music/SongList.module.styl"
import LinkSvg from "@images/Common__icn__link.svg"

export default function SongList({data})
{
    return(
        <React.Fragment>
        { data.length !== 0 ?
        <div className={css.container}>
            <div className={css.list}>
                {data.map(({ node },i)=>{
                    const { title, content, acf, featured_media } = node
                    const { url } = acf
                    if( url ){
                        return(
                            <Link to={url} target="_blank" className={css.item} key={i}>
                                {/* <span className={css.now}>配信中</span> */}
                                <figure className={css.thumbnail}>
                                    <FluidImage fluid={featured_media.localFile.childImageSharp.thumbnail } />
                                </figure>
                                <h4 className={css.musicName}>
                                    {title}
                                    <span className={css.icon}><LinkSvg/></span>
                                </h4>
                                <p className={css.musicText} dangerouslySetInnerHTML={{ __html: content }} />
                            </Link>
                        )
                    } else {
                        return (
                            <div className={css.item} key={i}>
                                <span className={css.commingsoon}>まもなく配信</span>
                                <figure className={css.thumbnail}>
                                 <FluidImage fluid={featured_media.localFile.childImageSharp.thumbnail }/>
                                </figure>
                                <h4 className={css.musicName}>{title}</h4>
                                <p className={css.musicText} dangerouslySetInnerHTML={{ __html: content }} />
                            </div>
                        )
                    }
                })}
            </div>
        </div>
        :
        <ul className={css.container}>
            <li className={css.nopost}>投稿はまだありません。</li>
        </ul>
        }
        </React.Fragment>
    )
}
