import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useTranslation } from 'react-i18next'
import { useStores } from '@hooks/useStores'
import { useObserver } from "mobx-react-lite"

import SEO from "@components/Seo"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Header from "@components/blocks/global/Header"
import PageHeader from "@components/blocks/common/PageHeader"
import PageSectionTitle from "@components/blocks/common/PageSectionTitle"
// import PageSectionParagraph from "@components/blocks/common/PageSectionParagraph"
// import MusicList from "@components/blocks/music/List"
import SongList from "@components/blocks/music/SongList"
import OfficalWorks from "@components/blocks/index/OfficialWorks"
import Vocaloid from "@components/blocks/index/Vocaloid"
// import StreamingAndDownload from "@components/blocks/music/StreamingAndDownload"
// import ContentListV2 from "@components/ContentList-v2"
import MovieList from "@components/blocks/songs/MovieList"
import SpecialContent from "@components/blocks/songs/SpecialContent"

import css from "@css/components/blocks/common/Page.module.styl"
// import Iframe from "react-iframe"
// import { FluidImage } from "@components/Image"

export default function Music({data}) {
	const { t } = useTranslation()
	const { appStore } = useStores()
	const [currentLang] = useObserver(() => [
		appStore.currentLang
	])
	const { thumbnail, musicStore, movieData, musicVideo, songs } = data
    console.log('test',songs)
	const musicList = [
        {
            title: `MIRAI`,
            text: `作詞・作曲 / 佐藤貴文（BNSI）`,
            url: `https://nex-tone.link/A00087647`,
            img: data.music01,
        }, {
            title: `future beat`,
            text: `作詞・作曲 / ミフメイ（BNSI）`,
            url: `https://nex-tone.link/A00087648`,
            img: data.music02,
        }, {
            title: `Future Melody -心から未来へ-`,
            text: `作曲 / Takahiro Eguchi (SuperSweep)<br/>作詞 / Fumihisa Tanaka (SuperSweep)`,
            url: `https://nex-tone.link/A00087649`,
            img: data.music03,
        }, {
            title: `Future Wonderland`,
            text: `作詞・作曲 / kors k(EDP)`,
            url: `https://nex-tone.link/A00088290`,
            img: data.music04,
        }, {
            title: `Mirai Rolling Bass`,
            text: `作詞・作曲 / AJURIKA`,
            url: `https://nex-tone.link/A00088291`,
            img: data.music05,
        }, {
            title: `同じかもね`,
            text: `作詞・作曲 / 岡田 祥（BNSI）`,
            url: 'https://nex-tone.link/A00088892',
            img: data.music06,
        }, {
            title: `オーバーライド`,
            text: `作詞・作曲 / picco`,
            url: 'https://nex-tone.link/A00088891',
            img: data.music07,
        },
        // {
        //     title: ``,
        //     text: ``,
        //     url: false,
        //     img: data.musicComingsoon,
        //     commingsoon: true,
        // },
    ]

	return (
		<React.Fragment>
			<SEO title={'SONGS'} />
			<main className={css.container} id="main">
				<PageHeader l1="SONGS" l2={t('music__headerTitle2')} />
				<div className={css.content}>
					<div className={css.labo}>
						{/* <section className={css.section}>
							<StreamingAndDownload/>
						</section> */}
						{/* <section className={css.section}>
							<PageSectionTitle l1={t('music__sectionTitle2_1')} l2={t('music__sectionTitle2_2')} />
							<ContentListV2 type="movie" data={movieData.edges} isThumbnailTitle={true} />
						</section> */}
						<section className={css.section}>
							{/* <PageSectionTitle l1={t('music__sectionTitle1_1')} l2={t('music__sectionTitle1_2')} /> */}
							<SongList data={songs.edges}/>
						</section>
						<section className={css.section}>
							<PageSectionTitle l1={t('music__sectionTitle2_1')} l2={t('music__sectionTitle2_2')} />
							<MovieList data={musicVideo.edges} currentLang={currentLang} />
						</section>
						<SpecialContent data={data}/>
						<section className={css.section}>
							<PageSectionTitle l1={t('index__supportTitleVocaloid')} l2={t('index__supportTitle1')} />
							<Vocaloid contentMode/>
						</section>
						<section className={css.section}>
							<PageSectionTitle l1={t('index__supportTitleOfficialWorks')} l2={t('index__officialWorksTitle2')} />
							<OfficalWorks contentMode />
						</section>
					</div>
				</div>
			</main>
			<FooterSns />
			<Footer />
		</React.Fragment>
	)
}

export const pageQuery = graphql`
    query {
        thumbnail : file(relativePath: { eq: "sample__img__thumbnail02@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        songs : allWordpressWpSongs (
			sort: { fields: date, order: DESC }
		){
            edges {
                node {
					id
					status
                    title
                    content
                    date(formatString: "YYYY-MM-DD")
                    acf {
						url
					}
                    featured_media {
                        localFile {
                            childImageSharp {
                                thumbnail: fluid(maxWidth: 640) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
                }
            }
		}
        musicVideo : allWordpressWpMusicVideo (
			sort: { fields: date, order: ASC }
		){
            edges {
                node {
					id
					status
                    title
                    content
                    date(formatString: "YYYY-MM-DD")
                    acf {
						youtube_id
					}
                }
            }
		}
		movieData: allWordpressWpMovie (sort: { fields: date, order: DESC }) {
            edges {
            	node {
            		id
            		slug
            		title
            		content
            		date(formatString: "MMMM DD, YYYY")
            		featured_media {
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1280) {
                                    ...GatsbyImageSharpFluid
                                }
                                thumbnail: fluid(maxWidth: 640) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
                    acf {
                        movie_youtube_id
                    }
            	}
            }
        }
		music01 : file(relativePath: { eq: "Anniversary__img__music-01@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music02 : file(relativePath: { eq: "Anniversary__img__music-02@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music03 : file(relativePath: { eq: "Anniversary__img__music-03@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music04 : file(relativePath: { eq: "Anniversary__img__music-04@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music05 : file(relativePath: { eq: "Anniversary__img__music-05@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music06 : file(relativePath: { eq: "Anniversary__img__music-06@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music07 : file(relativePath: { eq: "Anniversary__img__music-07@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music08 : file(relativePath: { eq: "Anniversary__img__music-08@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
		spContentBg : file(relativePath: { eq: "Anniversary__img__bg.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
            publicURL
        }
		spContentChara : file(relativePath: { eq: "Anniversary__img__chara@2x.png" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
    }
`