import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image"
import Iframe from "react-iframe"
import { FluidImage } from "@components/Image"
// import Link from "@components/Link"

import css from "@css/components/blocks/music/SpecialContent.module.styl"

export default function VocaloidMovieList({data, currentLang}){
	return(
        <div className={css.container}>
            <section className={css.section}>
                <div className={css.movieBg}>
                    <div className={css.movieBg__img} style={{backgroundImage: `url(${data.spContentBg.publicURL})`}}></div>
                </div>
                <div className={css.sectionInner}>
                    <h2 className={css.titleWhite}>BanaDIVE™AXによる特別DJ映像配信！</h2>
                    <div className={`${css.textWhite} ${css.width60}`}>
                        <p>
                            バンダイナムコ研究所のインタラクティブバーチャルパフォーマンスシステムBanaDIVE™AXによるミライ小町スペシャルDJ映像をご覧ください！
                        </p>
                    </div>
                    <div className={css.movieWrapper}>
                        <div className={css.movie}>
                            <Iframe url={`https://www.youtube.com/embed/PwPfo0s5IgE`} width="640px" height="400px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                        </div>
                    </div>
                    <figure className={css.movieChara}>
                        <FluidImage data={data.spContentChara}/>
                    </figure>
                </div>
            </section>
        </div>
	)
}
